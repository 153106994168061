<template>
  <b-sidebar
    id="add-new-goat-sidebar"
    :visible="isAddNewGoatSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-goat-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Monitoring
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
        encytype="multipart/form-data"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Nama Kambing"
            rules="required"
          >
            <b-form-group
              label="Nama Kambing"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="goatData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Goat Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Jenis Kelamin"
            rules="required"
          >
            <b-form-group
              label="Jenis Kelamin"
              label-for="gender"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="goatData.gender"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="gender"
                :clearable="false"
                input-id="gender"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Gender -->
          <validation-provider
            #default="validationContext"
            name="Kandang"
            rules="required"
          >
            <b-form-group
              label="Pilih Kandang"
              label-for="pens_available"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="optionData.pen_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="penOptions"
                :clearable="false"
                input-id="pens_available"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Device Available -->
          <validation-provider
            #default="validationContext"
            name="alat_iot"
          >
            <b-form-group
              label="Alat IoT"
              label-for="available_devices"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="optionData.device_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="deviceOptions"
                :clearable="false"
                input-id="available_devices"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Status Available -->
          <validation-provider
            #default="validationContext"
            name="status"
            rules="required"
          >
            <b-form-group
              label="Status"
              label-for="statuses"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="optionData.status_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusesOptions"
                :clearable="false"
                input-id="statuses"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Status Available -->
          <validation-provider
            #default="validationContext"
            name="Status Out"
            rules="required"
          >
            <b-form-group
              label="Status Out"
              label-for="statusOuts"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="optionData.status_out_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOutsOptions"
                :clearable="false"
                input-id="statusOuts"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          

          <!-- Birth Place -->
          <validation-provider
            #default="validationContext"
            name="Birth Place"
            rules="required"
          >
            <b-form-group
              label="Birth Place"
              label-for="birth_place"
            >
              <b-form-input
                id="birth_place"
                v-model="goatData.birth_place"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Goat Birth Place"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Birth Date -->
          <validation-provider
            #default="validationContext"
            name="Birth Date"
            rules="required"
          >
            <b-form-group
              label="Birth Date"
              label-for="birth_date"
            >
              <b-form-datepicker
                id="birth_date"
                placeholder="Choose a date"
                v-model="goatData.birth_date"
                :state="getValidationState(validationContext)"
                trim
              />
              
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Birth Weight -->
          <validation-provider
            #default="validationContext"
            name="Birth Weight"
            rules="required"
          >
            <b-form-group
              label="Birth Weight (Kg)"
              label-for="birth_weight"
            >
              <b-form-input
                id="birth_weight"
                v-model="goatData.birth_weight"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Birth Length -->
          <validation-provider
            #default="validationContext"
            name="Birth Length"
            rules="required"
          >
            <b-form-group
              label="Birth Length (cm)"
              label-for="birth_length"
            >
              <b-form-input
                id="birth_length"
                v-model="goatData.birth_length"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Birth Weight -->
          <validation-provider
            #default="validationContext"
            name="Weight"
            rules="required"
          >
            <b-form-group
              label="Weight (Kg)"
              label-for="current_weight"
            >
              <b-form-input
                id="current_weight"
                v-model="goatData.current_weight"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- current Length -->
          <validation-provider
            #default="validationContext"
            name="Length"
            rules="required"
          >
            <b-form-group
              label="Length (cm)"
              label-for="current_length"
            >
              <b-form-input
                id="current_length"
                v-model="goatData.current_length"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          
          <validation-provider
          #default="validationContext"
          name="Genetic Status"
        >
          <b-form-group
            label="Genetic Status"
            label-for="genetic_status"
          >
            <b-form-input
              id="genetic_status"
              v-model="goatData.genetic_status"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Genetic Status"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>


        <!-- Image Upload -->
          <validation-provider
          #default="validationContext"
          name="Foto"
        >
          <b-form-group
            label="Foto Hewan"
            label-for="image"
          >
            <label
              for="extension"
              class="mt-1">
              Pilih foto hewan
            </label>

            <b-form-file
            ref="refInputEl"
            @change="uploadImage"
            v-model="imageFile"
              id="extension"
              accept=".jpg, .png, .gif"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
       
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isAddButtonClick"
              v-if="$can('update', 'animal')"
            >
            <span v-if="!isAddButtonClick">Tambah</span>
            <b-spinner small v-if="isAddButtonClick" />
            <span class="sr-only" v-if="isAddButtonClick">Loading...</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
                    Batalkan
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSpinner, BCardText,BLink, BImg,BMedia, BMediaAside, BMediaBody,BSidebar, BForm, BFormGroup, BFormFile, BFormInput,BFormRadioGroup, BFormInvalidFeedback, BButton, BFormDatepicker } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onMounted, onUnmounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

import store from '@/store'
import router from '@/router'
import {computed} from 'vue'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import monitoringStoreModule from './monitoringStoreModule'

export default {
  components: {
    BSpinner,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormInvalidFeedback,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormFile, 
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink, 
    BImg,
    BCardText,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewGoatSidebarActive',
    event: 'update:is-add-new-goat-sidebar-active',
  },
  props: {
    isAddNewGoatSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      gender: [
        'Jantan',
        'Betina',
      ],
      isHaveParents: [
        { text: 'Ya', value: 'have', disabled: false },
        { text: 'Tidak', value: 'not_have', disabled: false },
      ],
      penOptions: [],
      deviceOptions: [],
      statusesOptions: [],
      statusOutsOptions: [],
    }
  },
  setup(props, { emit }) {
    // use Toast
    const toast = useToast()

    const MONITORING_GOAT_APP_STORE_MODULE_NAME = 'appMonitoringGoat'

    // Register module
    if (!store.hasModule(MONITORING_GOAT_APP_STORE_MODULE_NAME)) store.registerModule(MONITORING_GOAT_APP_STORE_MODULE_NAME, monitoringStoreModule)

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(MONITORING_GOAT_APP_STORE_MODULE_NAME)) store.unregisterModule(MONITORING_GOAT_APP_STORE_MODULE_NAME)
    // })


    // setup json post goat data

    const blankGoatData = {
      f_parennt_id: null,
      m_parent_id:null,
      name: '',
      genetic_status: '',
      birth_place: 'SMKN 1 Tulung Klaten',
      birth_date: '',
      birth_weight: '',
      birth_length: '',
      gender: '',
      pen_id:null,
      // start static data -- untuk sementara ngejar target
      pen_id: null, 
      give_birth_id: router.currentRoute.params.id, // kambing
      animal_category_id: 1, // kambing
      animal_status_id: 1, // penggemukan
      animal_status_out_id: 1, // active
      device_id: null, // active
      first_milked: null, // active
      // finish static data
      image: null,
    }
    const goatData = ref(JSON.parse(JSON.stringify(blankGoatData)))
    
    axios.get(`animals/${router.currentRoute.params.id}/detail`).then((response) => {
        const data = response.data.data

        goatData.value.name = data.animal_name
        goatData.value.genetic_status = data.genetic_status
        goatData.value.birth_place = data.birth_place
        goatData.value.birth_date = data.birth_date
        goatData.value.birth_weight = data.birth_weight
        goatData.value.birth_length = data.birth_length
        goatData.value.current_weight = data.current_weight
        goatData.value.current_length = data.current_length
        goatData.value.gender = data.gender
        goatData.value.pen_id = null
        goatData.value.animal_category_id = 1 // kambing
        goatData.value.device_id = data.device_id // active
        goatData.value.first_milked = data.first_milked // active
        goatData.value.image = null
    })
    
    // set default selected have parents to have
    const blankOptionData =  {
      pen_id: null,
      device_id: null,
      status_id: null,
      status_out_id: null,
    }

    const optionData = ref(JSON.parse(JSON.stringify(blankOptionData)));
    
    // when click Cancle button
    const resetGoatData = () => {
      goatData.value = JSON.parse(JSON.stringify(blankGoatData))
      optionData.value = ref(JSON.parse(JSON.stringify(blankOptionData)));
    }

    
    // handleUploadImg
    const refInputEl = ref(null)
    const imageFile = ref(null)
    const uploadImage = (event) => {
      goatData.value.image = event.target.files[0]
    }

    const convertGoatDataToFormData = (goatData) => {
      const formData = new FormData(); 
      if(goatData.value.image !== null){
        formData.append("image", goatData.value.image)
      }
      if(goatData.value.device_id !== null){
        formData.append("device_id", goatData.value.device_id)
      }
      formData.append("name", goatData.value.name)
      formData.append("genetic_status", goatData.value.genetic_status)
      formData.append("birth_place", goatData.value.birth_place)
      formData.append("birth_date", goatData.value.birth_date)
      formData.append("birth_weight", goatData.value.birth_weight)
      formData.append("birth_length", goatData.value.birth_length)
      formData.append("current_weight", goatData.value.current_weight)
      formData.append("current_length", goatData.value.current_length)
      formData.append("gender", goatData.value.gender)
      formData.append("pen_id", goatData.value.pen_id)
      formData.append("give_birth_id", goatData.value.give_birth_id)
      formData.append("animal_category_id", 1)
      formData.append("animal_status_id", goatData.value.animal_status_id)
      formData.append("animal_status_out_id", goatData.value.animal_status_out_id)
      

      return formData;
    }

    // when click Add button
    // enabled button add
    const isAddButtonClick = ref(false)

    const onSubmit = async () => {
      isAddButtonClick.value = !isAddButtonClick.value
      goatData.value.pen_id = optionData.value.pen_id !== null ? optionData.value.pen_id.value : null ;
      goatData.value.device_id = optionData.value.device_id !== null ? optionData.value.device_id.value : null;
      goatData.value.status_id = optionData.value.status_id !== null ? optionData.value.status_id.value : null;
      goatData.value.status_out_id = optionData.value.status_out_id !== null ? optionData.value.status_out_id.value : null;
      
      const formData = convertGoatDataToFormData(goatData)

      await store.dispatch('appMonitoringGoat/updateMonitoring', formData).then((response) => {
    
        if(response.data.meta.code === 200){
          toast({
            component: ToastificationContent,
            props: {
              title: 'Berhasil memperbaruhi kondisi hewan',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          resetGoatData()
          isAddButtonClick.value = !isAddButtonClick.value
          emit('refetch-data')
          emit('update:is-add-new-goat-sidebar-active', false)
        }
      }).catch((error) => {
        toast({
            component: ToastificationContent,
            props: {
              title: 'Periksa kembali seluruh form',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        isAddButtonClick.value = !isAddButtonClick.value
        console.log(error.response)
      })
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetGoatData)

    return {
      optionData,
      goatData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      
      //input file
      imageFile,
      isAddButtonClick,
      uploadImage,
      refInputEl
    }
  },
  mounted() {

    // get available pens
   
    // get available pens
    this.$http.get('/pens/available').then(response => {
      const availablePens = response.data.data

      availablePens.forEach(pen => {
        this.penOptions.push(
          {
            label: pen.name, 
            value:  pen.id
          }
        )
      });
    });

    // get available device
    this.$http.get('/devices/available').then(response => {
      const availableDevices = response.data.data

      availableDevices.forEach(device => {
        this.deviceOptions.push(
          {
            label: device.name, 
            value:  device.id
          }
        )
      });
    });

  },
  created(){
    this.$http.get('/statuses/all').then(response => {
      const statuses = response.data.data
     
      statuses.forEach(status => {
        this.statusesOptions.push(
          {
            label: status.name, 
            value:  status.id
          }
        )
      });
    })

    this.$http.get('/status-outs').then(response => {
      const statusOuts = response.data.data
     
      statusOuts.forEach(status => {
        this.statusOutsOptions.push(
          {
            label: status.name, 
            value:  status.id
          }
        )
      });
    })
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-goat-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
